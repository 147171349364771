var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"page",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Vistorias "+_vm._s(_vm.total > 0 ? ("(" + _vm.total + ")") : "")+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"value":_vm.q,"append-icon":"mdi-magnify","solo":"","label":"Digite para buscar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search($event)}}})],1),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.limit,"loading":_vm.$wait.waiting('loading'),"fixed-header":"","height":"inherit","hide-default-footer":""},on:{"click:row":function($event){return _vm.goToDetail($event)}},scopedSlots:_vm._u([{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vehicleModel(item))+" ")]}},{key:"item.inspectionStatus",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{staticStyle:{"flex":"none"},attrs:{"small":"","color":_vm.mapStatusColor(item.status)}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.mapStatusIcon(item.status)))]),_vm._v(" "+_vm._s(_vm.mapStatus(item.status))+" ")],1):_vm._e()]}},{key:"item.contractStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ml-2",staticStyle:{"flex":"none"},attrs:{"small":"","color":_vm.mapContractColor(item.contract && item.contract.status),"dark":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v(_vm._s(_vm.mapContractIcon(item.contract && item.contract.status)))]),_vm._v(" "+_vm._s(_vm.mapContractStatus(item.contract && item.contract.status))+" ")],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("FormatDate")(item.createdAt,"datetime"))+" "+_vm._s(item.author ? ("(" + (item.author.name) + ")") : "")+" ")]}}])},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"no-data-found"},[_vm._v(" Nenhum resultado encontrado "),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.fetch(_vm.page)}}},[_vm._v(" Carregar novamente ")])],1)])],2),_c('div',{staticClass:"pagination text-center py-2"},[_c('v-pagination',{attrs:{"value":_vm.page,"length":_vm.pageLength},on:{"input":function($event){return _vm.fetch($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }