<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6"
    >
      <v-card ref="form">
        <h2 class="primary--text pl-3 pt-3">
          {{ isEdit ? "Atualizar dados da empresa" : "Cadastrar empresa" }}
        </h2>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Data de criação:</strong>
            {{ data.createdAt | FormatDate('datetime') }}
          </div>

          <v-switch
            v-if="isEdit"
            v-model="data.active"
            label="Empresa ativo"
          ></v-switch>

          <v-text-field
            v-model="data.name"
            label="Nome da empresa"
            placeholder="Ex: Company Inc."
            required
          ></v-text-field>

          <v-text-field
            v-model="data.cnpj"
            label="CNPJ"
            placeholder="Ex: 11.111.111/0001-11"
            v-mask="['##.###.###/####-##']"
            autocomplete="off"
            required
          ></v-text-field>

          <v-text-field
            v-model="data.pagarme.apiToken"
            :append-icon="showToken ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showToken ? 'text' : 'password'"
            label="Cadastrar novo token do Pagar.me"
            :min="10"
            @click:append="showToken = !showToken"
          ></v-text-field>
          <div class="gray--text subtitle" v-if="data.pagarme && data.pagarme.hasApiToken">
            * Token do Pagarme já foi configurado. Se você clicar em salvar com o campo acima em branco, o token não será alterado.
          </div>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import { mapActions } from "vuex";
import { mapWaitingActions } from "vue-wait";
import checksum from '@/helpers/checksum'
import _ from "lodash";

export default {
  name: "CompanyEditor",

  data() {
    return {
      showToken: true,
      isEdit: false,
      initialState: null,
      data: {
        createdAt: null,
        active: null,
        name: null,
        cnpj: null,
        pagarme: {
          hasApiToken: null
        }
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      data.pagarme.apiToken = ""
      this.data = _.cloneDeep(data)
      this.isEdit = true;
    } else if (id && id != 'new' && !data) {
      this.data = null
      return this.$router.replace({ name: "company" });
    }

    setTimeout(() => {
      this.initialState = _.cloneDeep(this.data)
    }, 100)
  },

  watch: {
    dataIsModified(val) {
      if(!val) {
        return window.onbeforeunload = null
      }
      window.onbeforeunload = function(){
        return 'exit';
      };
    }
  },

  async beforeRouteLeave(to, from, next){
    if(!this.dataIsModified || this.confirmExit) {
      return next()
    }

    if (window.confirm("Você tem alterações que não foram salvas. Deseja realmente sair?")) {
      return next()
    }
    try {
      await this.$router.replace(from)
    } catch (err) {
      return err;
    }
  },

  computed: {
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data)
    },

    allRequiredFieldsFilled() {
      const fields = [
        'name', 
        'cnpj', 
      ]
      return fields.every((field) => this.data[field])
    },
  },

  methods: {
    ...mapActions("company", [
      "create",
      "update",
    ]),
    ...mapWaitingActions("company", {
      create: "creating",
      update: "updating",
    }),

    submit() {
      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({ type: "success", title: 'Sucesso', message: "Empresa cadastrada com sucesso" });
      this.reset(result)
      this.$router.replace({ name: 'company' })
    },

    async updateRequest() {
      const data = _.cloneDeep(this.data);
      if(!data.pagarme.apiToken) {
        delete data.pagarme.apiToken
      }
      const result = await this.update({
        id: this.data.id,
        company: data,
      });
      if (!result) return;
      
      this.reset(result)
      this.$notify({ type: "success", title: 'Sucesso', message: "Dados atualizados com sucesso" });
    },

    reset(result) {
      result.pagarme = {
        apiToken: "",
        hasApiToken: true
      }
      this.initialState = Object.assign({}, result)
      this.data = Object.assign({}, result)
    }
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
</style>
