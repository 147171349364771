<template>
  <v-card flat class="page">
    <v-card-title>
      Comunicados {{ total > 0 ? `(${total})` : ''}}
      <v-spacer></v-spacer>
      <v-text-field
        :value="q"
        @input="search($event)"
        append-icon="mdi-magnify"
        solo
        label="Digite para buscar"
        single-line
        hide-details
      ></v-text-field>

      <v-btn
        @click="$router.push({ path: '/promotional/new' })"
        class="ma-3"
        color="green"
        dark
        x-large
      >
        <v-icon color="white" class="mr-2">mdi-plus</v-icon> Adicionar
      </v-btn>
    </v-card-title>
    <v-data-table
      class="table"
      :headers="headers"
      :items="items"
      :items-per-page="limit"
      :loading="$wait.waiting('loading')"
      @click:row="goToDetail($event)"
      fixed-header
      height="inherit"
      hide-default-footer
    >
      <template slot="no-data">
        <div class="no-data-found">
          Nenhum resultado encontrado
          <v-btn @click="fetch(page)" color="blue" dark class="mt-2">
            Carregar novamente
          </v-btn>
        </div>
      </template>

      <template v-slot:item.roles="{ item }">
        <div v-if="item.target && item.target.all">
          Todos
        </div>

        <template v-else-if="item.target && item.target.roles && item.target.roles.length">
          <v-chip
            style="flex: none"
            small
            v-for="role in item.target.roles"
            :key="role"
          >
            {{ mapTargetRoles(role) }}
          </v-chip>
        </template>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | FormatDate('datetime') }}
      </template>
    </v-data-table>
    <div class="pagination text-center py-2">
      <v-pagination
        :value="page"
        @input="fetch($event)"
        :length="pageLength"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import _ from 'lodash'

export default {
  name: 'Promotionals',

  data() {
    return {
      q: '',
      limit: 10,
      page: 1,
      headers: [
        {
          text: 'Título',
          align: 'start',
          filterable: false,
          value: 'title',
        },
        { text: "Enviado para", value: "roles" },
        { text: 'Link', value: 'link' },
        { text: 'Telefone', value: 'phone' },
        { text: 'Criado em', value: 'createdAt' },
      ],
      result: null
    }
  },

  async created() {
    this.fetch(this.page)
  },

  computed: {
    pageLength() {
      return Math.ceil(this.total/this.limit) || 1
    },

    items() {
      return _.get(this.result, 'hits', [])
    },

    total() {
      return _.get(this.result, 'total', 0)
    }
  },

  methods: {
    ...mapActions("promotional", ["list"]),
    ...mapWaitingActions("promotional", {
      list: 'loading'
    }),

    async fetch(page) {
      this.page = page
      this.result = await this.list({
        page: page - 1,
        limit: this.limit
      })
    },


    mapTargetRoles(role) {
      return (
        {
          associated: "Associado",
          employee: "Consultor",
          owner: "Consultor líder",
          admin: "Admin",
          backoffice: "Backoffice",
        }[role]
      );
    },

    goToDetail(row) {
      this.$router.push({
        name: 'promotional-editor',
        params: {
          id: row.id,
          data: row
        }
      })
    },

    search: _.debounce(async function(q){
      this.result = await this.list({ page: 0, limit: this.limit, q: q })
    }, 500, { leading: false, trailing: true }),
  }

}
</script>
<style type="text/css" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table {
  flex: 1 1 auto;
  height: calc(100vh - 224px);
}
.table >>> tbody tr :hover {
  cursor: pointer;
}
.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.pagination {
  flex: none;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}
</style>