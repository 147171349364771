<template>
  <v-card flat class="page">
    <v-card-title>
      Usuários {{ total > 0 ? `(${total})` : ''}}
      <v-spacer></v-spacer>
      <v-row> 
        <v-col sm="12" md="5">
          <v-text-field
            :value="q"
            @input="search({ q: $event, active, roles })"
            append-icon="mdi-magnify"
            solo
            label="Digite para buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>

        <v-col sm="12" md="3">
          <v-select
            @input="search({ q, active: $event, roles })"
            :value="active"
            :items="activeItems"
            solo
            single-line
            hide-details
            width="100px"
          ></v-select>
        </v-col>

        <v-col sm="12" md="4">
          <v-select
            @input="search({ q, active, roles: $event })"
            :value="roles"
            :items="rolesItems"
            multiple
            solo
            placeholder="Filtrar por permissão"
            single-line
            hide-details
            style="max-width: 250px"
          ></v-select>
        </v-col>
      </v-row>
      <v-btn
        @click="$router.push({ path: '/user/new' })"
        class="ma-3"
        color="green"
        dark
        x-large
      >
        <v-icon color="white" class="mr-2">mdi-plus</v-icon> NOVO USUÁRIO
      </v-btn>
    </v-card-title>
    <v-data-table
      class="table"
      :headers="headers"
      :items="items"
      :items-per-page="limit"
      :loading="$wait.waiting('loading')"
      @click:row="goToDetail($event)"
      fixed-header
      height="inherit"
      hide-default-footer
    >
      <template slot="no-data">
        <div class="no-data-found">
          Nenhum resultado encontrado
          <v-btn @click="fetch(page)" color="blue" dark class="mt-2">
            Carregar novamente
          </v-btn>
        </div>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | FormatDate('datetime') }}
      </template>
      <template v-slot:item.roles="{ item }">
        <v-chip class="mr-2" v-for="role in prettyRoles(item.roles)" :key="role">{{ role }}</v-chip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip :color="item.active ? 'green' : 'red'">{{ item.active ? 'Ativo' : 'Inativo' }}</v-chip>
      </template>
    </v-data-table>
    <div class="pagination text-center py-2">
      <v-pagination
        :value="page"
        @input="fetch($event)"
        :length="pageLength"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import _ from 'lodash'

export default {
  name: 'User',

  data() {
    return {
      q: '',
      active: null,
      activeItems: [
        { text: 'TODOS', value: null },
        { text: 'ATIVO', value: true },
        { text: 'INATIVO', value: false },
      ],
      roles: null,
      rolesItems: [
        {
          text: 'Administrador',
          value: 'admin',
        },
        {
          text: 'Consultor Líder',
          value: 'owner'
        },
        {
          text: 'Consultor',
          value: 'employee'
        },
        {
          text: 'Backoffice',
          value: 'backoffice'
        }
      ],
      limit: 20,
      page: 1,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          filterable: false,
          value: 'name',
        },
        { text: 'CPF', value: 'cpf' },
        { text: 'Email', value: 'email' },
        { text: 'Permissão', value: 'roles' },
        { text: 'Status', value: 'active' },
        { text: 'Criado em', value: 'createdAt' },
      ],
      result: null
    }
  },

  async created() {
    this.fetch(this.page)
  },

  computed: {
    pageLength() {
      return Math.ceil(this.total/this.limit) || 1
    },

    items() {
      return _.get(this.result, 'hits', [])
    },

    total() {
      return _.get(this.result, 'total', 0)
    }
  },

  methods: {
    ...mapActions("user", ["list"]),
    ...mapWaitingActions("user", {
      list: 'loading'
    }),

    async fetch(page) {
      this.page = page
      this.result = await this.list({
        page: page - 1,
        limit: this.limit
      })
    },

    goToDetail(row) {
      this.$router.push({
        name: 'user-editor',
        route: '/user/' + row.id,
        params: {
          id: row.id,
          data: row
        }
      })
    },

    search: _.debounce(async function(params){
      this.result = await this.list({ page: 0, limit: this.limit, ...params })
    }, 500, { leading: false, trailing: true }),


    mapRoles(role) {
      return {
        'admin': 'Administrador',
        'owner': 'Consultor líder',
        'employee': 'Consultor',
        'backoffice': 'Backoffice'
      }[role]
    },

    prettyRoles(roles) {
      return roles.map((role) => this.mapRoles(role))
    }
  }

}
</script>
<style type="text/css" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table {
  flex: 1 1 auto;
  height: calc(100vh - 224px);
}
.table >>> tbody tr :hover {
  cursor: pointer;
}
.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.pagination {
  flex: none;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}
</style>