<template>
  <v-card flat class="page">
    <v-card-title>
      Vistorias {{ total > 0 ? `(${total})` : "" }}
      <v-spacer></v-spacer>
      <v-text-field
        :value="q"
        @input="search($event)"
        append-icon="mdi-magnify"
        solo
        label="Digite para buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="table"
      :headers="headers"
      :items="items"
      :items-per-page="limit"
      :loading="$wait.waiting('loading')"
      @click:row="goToDetail($event)"
      fixed-header
      height="inherit"
      hide-default-footer
    >
      <template slot="no-data">
        <div class="no-data-found">
          Nenhum resultado encontrado
          <v-btn @click="fetch(page)" color="blue" dark class="mt-2">
            Carregar novamente
          </v-btn>
        </div>
      </template>
      <template v-slot:item.vehicle="{ item }">
        {{ vehicleModel(item) }}
      </template>

      <template v-slot:item.inspectionStatus="{ item }">
        <v-chip
          style="flex: none"
          small
          :color="mapStatusColor(item.status)"
          v-if="item.status"
        >
          <v-icon size="18" class="mr-1">{{
            mapStatusIcon(item.status)
          }}</v-icon>
          {{ mapStatus(item.status) }}
        </v-chip>
      </template>

      <template v-slot:item.contractStatus="{ item }">
        <v-chip
          style="flex: none"
          small
          class="ml-2"
          :color="mapContractColor(item.contract && item.contract.status)"
          dark
        >
          <v-icon size="18" class="mr-1">{{
            mapContractIcon(item.contract && item.contract.status)
          }}</v-icon>
          {{ mapContractStatus(item.contract && item.contract.status) }}
        </v-chip>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | FormatDate("datetime") }}
        {{ item.author ? `(${item.author.name})` : "" }}
      </template>
    </v-data-table>
    <div class="pagination text-center py-2">
      <v-pagination
        :value="page"
        @input="fetch($event)"
        :length="pageLength"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import { mapWaitingActions } from "vue-wait";
import _ from "lodash";

export default {
  name: "Inspection",

  data() {
    return {
      q: "",
      limit: 50,
      page: 1,
      headers: [
        {
          text: "Associado",
          align: "start",
          filterable: false,
          value: "specification.associated.name",
        },
        { text: "CPF/CNPJ", value: "specification.associated.document" },
        { text: "Veículo", value: "vehicle" },
        { text: "Status da vistoria", value: "inspectionStatus" },
        { text: "Status do contrato", value: "contractStatus" },
        { text: "Criado em", value: "createdAt" },
      ],
      result: null,
    };
  },

  async created() {
    this.fetch(this.page);
  },

  computed: {
    pageLength() {
      return Math.ceil(this.total / this.limit) || 1;
    },

    items() {
      return _.get(this.result, "hits", []);
    },

    total() {
      return _.get(this.result, "total", 0);
    },
  },

  methods: {
    ...mapActions("inspection", ["list"]),
    ...mapWaitingActions("inspection", {
      list: "loading",
    }),

    mapStatusColor(status) {
      return {
        draft: "grey",
        waiting_approval: "yellow",
        waiting_correction: "yellow",
        approved: "green",
      }[status];
    },

    mapStatusIcon(status) {
      return {
        draft: "mdi-file-document-edit",
        waiting_approval: "mdi-clock-outline",
        waiting_correction: "mdi-alert",
        approved: "mdi-check-circle-outline",
      }[status];
    },

    mapStatus(status) {
      return {
        draft: "Em preenchimento",
        waiting_approval: "Em análise",
        waiting_correction: "Corrigir",
        approved: "Aprovado",
      }[status];
    },

    mapContractColor(status) {
      return (
        {
          waiting_signature: "blue-medium",
          signed: "green",
        }[status] || "Aguardando vistoria"
      );
    },

    mapContractIcon(status) {
      return (
        {
          waiting_signature: "mdi-file-document-edit-outline",
          signed: "mdi-clipboard-check-outline",
        }[status] || "mdi-clock-outline"
      );
    },

    mapContractStatus(status) {
      return (
        {
          waiting_signature: "Contrato gerado",
          signed: "Contrato assinado",
        }[status] || "Em preenchimento"
      );
    },

    async fetch(page) {
      this.page = page;
      this.result = await this.list({
        includes: ["quotation", "author", "contract"],
        page: page - 1,
        limit: this.limit,
      });
    },

    goToDetail(row) {
      this.$router.push({
        name: "inspection-editor",
        params: {
          id: row.id,
          data: row,
        },
      });
    },

    vehicleModel(inspection) {
      return `${_.get(inspection, "quotation.vehicle.brand", "")} ${_.get(
        inspection,
        "quotation.vehicle.model",
        ""
      )} (${_.get(inspection, "quotation.vehicle.year", "")})`;
    },

    search: _.debounce(
      async function (q) {
        this.result = await this.list({ page: 0, limit: this.limit, q: q });
      },
      500,
      { leading: false, trailing: true }
    ),
  },
};
</script>
<style type="text/css" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table {
  flex: 1 1 auto;
  height: calc(100vh - 205px);
}
.table >>> tbody tr :hover {
  cursor: pointer;
}
.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.pagination {
  flex: none;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}
</style>