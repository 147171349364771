<template>
  <v-card flat class="page">
    <v-card-title>
      Eventos Comunicados {{ total > 0 ? `(${total})` : ''}}
      <v-spacer></v-spacer>
      <v-text-field
        :value="q"
        @input="search($event)"
        append-icon="mdi-magnify"
        solo
        label="Digite para buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="table"
      :headers="headers"
      :items="items"
      :items-per-page="limit"
      :loading="$wait.waiting('loading')"
      @click:row="goToDetail($event)"
      fixed-header
      height="inherit"
      hide-default-footer
    >
      <template slot="no-data">
        <div class="no-data-found">
          Nenhum resultado encontrado
          <v-btn @click="fetch(page)" color="blue" dark class="mt-2">
            Carregar novamente
          </v-btn>
        </div>
      </template>

      <template v-slot:item.associated="{ item }">
        {{ (item.associated && item.associated.name || 'sem nome') }}
        <i>({{ item.associated && item.associated.cpf }})</i>
      </template>

      <template v-slot:item.kind="{ item }">
        <img class="mr-2" :src="mapOccurrenceIcon(item.kind)" width="18" height="18" /> {{ mapKind(item.kind) }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | FormatDate('datetime') }}
      </template>
    </v-data-table>
    <div class="pagination text-center py-2">
      <v-pagination
        :value="page"
        @input="fetch($event)"
        :length="pageLength"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import _ from 'lodash'

export default {
  name: 'Occurrence',

  data() {
    return {
      q: '',
      limit: 20,
      page: 1,
      headers: [
        { text: 'Data do registro', value: 'createdAt' },
        {
          text: 'Associado',
          align: 'start',
          filterable: false,
          value: 'associated',
        },
        { text: 'Tipo do evento', value: 'kind' },
        { text: 'Descrição', value: 'description' },
      ],
      result: null
    }
  },

  async created() {
    this.fetch(this.page)
  },

  computed: {
    pageLength() {
      return Math.ceil(this.total/this.limit) || 1
    },

    items() {
      return _.get(this.result, 'hits', [])
    },

    total() {
      return _.get(this.result, 'total', 0)
    }
  },

  methods: {
    ...mapActions("occurrence", ["list"]),
    ...mapWaitingActions("occurrence", {
      list: 'loading'
    }),

    mapKind(kind) {
      return {
        'theft': 'Roubo e/ou furto',
        'collision': 'Colisão'
      }[kind]
    },

    mapOccurrenceIcon(kind) {
      return (
        {
          theft: require("@/assets/thief-icon.png"),
          collision: require("@/assets/collision-icon.png"),
        }[kind]
      );
    },

    async fetch(page) {
      this.page = page
      this.result = await this.list({
        page: page - 1,
        limit: this.limit
      })
    },

    goToDetail(row) {
      this.$router.push({
        name: 'occurrence-editor',
        params: {
          id: row.id,
          data: row
        }
      })
    },

    search: _.debounce(async function(q){
      this.result = await this.list({ page: 0, limit: this.limit, q: q })
    }, 500, { leading: false, trailing: true }),
  }

}
</script>
<style type="text/css" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table {
  flex: 1 1 auto;
  height: calc(100vh - 224px);
}
.table >>> tbody tr :hover {
  cursor: pointer;
}
.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.pagination {
  flex: none;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}
</style>