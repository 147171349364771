<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <h2 class="primary--text pl-3 pt-3">
          {{ isEdit ? "Atualizar dados da oficina" : "Cadastrar oficina" }}
        </h2>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Data de criação:</strong>
            {{ data.createdAt | FormatDate("datetime") }}
          </div>

          <v-text-field
            v-model="data.name"
            label="Nome da oficina"
            placeholder="Ex: Auto Repair"
            required
          ></v-text-field>
          <v-text-field
            v-model="data.telephone"
            label="Telefone"
            placeholder="Ex: (11) 99999-9999"
            v-mask="['(##) #####-####']"
          ></v-text-field>
          <v-text-field
            v-model="data.address"
            label="Endereço da oficina"
            placeholder="Ex: Rua Moreira Abis 333, Jd Arco Iris - São Paulo/SP"
            required
          ></v-text-field>
          <div id="map" v-if="hasCoordinates"></div>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isEdit"
            color="red"
            large
            class="btn mt-4 red white--text"
            @click="deleteCarRepair()"
          >
            Deletar Oficina
          </v-btn>
          <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { mapWaitingActions } from "vue-wait";
import checksum from "@/helpers/checksum";
import _ from "lodash";

export default {
  name: "CarRepairEditor",

  data() {
    return {
      isEdit: false,
      initialState: null,
      data: {
        createdAt: null,
        name: null,
        location: {
          coordinates: [],
        },
        address: null,
        telephone: null,
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != "new" && !data) {
      this.data = null;
      return this.$router.replace({ name: "car-repair" });
    }

    this.loadMap();
    setTimeout(() => {
      this.initialState = Object.assign({}, this.data);
    }, 100);
  },

  watch: {
    dataIsModified(val) {
      if (!val) {
        return (window.onbeforeunload = null);
      }
      window.onbeforeunload = function () {
        return "exit";
      };
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.dataIsModified || this.confirmExit) {
      return next();
    }

    if (
      window.confirm(
        "Você tem alterações que não foram salvas. Deseja realmente sair?"
      )
    ) {
      return next();
    }
    try {
      await this.$router.replace(from);
    } catch (err) {
      return err;
    }
  },

  computed: {
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data);
    },

    allRequiredFieldsFilled() {
      const fields = ["name", "address", "telephone"];
      return fields.every((field) => this.data[field]);
    },

    hasCoordinates() {
      return _.get(this.data, "location.coordinates.length", null);
    },
  },

  methods: {
    ...mapActions("carRepair", ["create", "update", "delete"]),
    ...mapWaitingActions("carRepair", {
      create: "creating",
      update: "updating",
    }),

    submit() {
      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    deleteCarRepair() {
      if (this.isEdit) {
        return this.deleteRequest();
      }
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Oficina cadastrada com sucesso",
      });
      this.reset(result);
      this.$router.replace({ name: "car-repair" });
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.update({
        id: this.data.id,
        carRepair: data,
      });
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Dados atualizados com sucesso",
      });
      this.reset(result);
      this.loadMap();
    },

    async deleteRequest() {
      const result = await this.delete(this.data.id);
      if (!result) return;

      this.$notify({
        type: "success",
        title: "Sucesso",
        message: "Oficina deletada com sucesso",
      });
      this.reset(result);
    },

    reset(result) {
      this.initialState = Object.assign({}, result);
      this.data = Object.assign({}, result);
    },

    loadMap() {
      if (!this.data.location.coordinates[0]) return;

      setTimeout(() => {
        const google = window.google;
        const lat = _.get(this.data, "location.coordinates[1]", null);
        const lng = _.get(this.data, "location.coordinates[0]", null);

        if (!lat || !lng) return;
        const myLatLng = { lat, lng };
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 15,
          center: myLatLng,
        });
        new google.maps.Marker({
          position: myLatLng,
          map,
        });
      }, 500);
    },
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
#map {
  width: 100%;
  height: 300px;
}
</style>
