import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import createStore from "./store";

// plugins
import vuetify from "@/plugins/vuetify";
import elementUI from "@/plugins/elementUI";
import formatDate from "@/plugins/formatDate";
import axios from "@/plugins/axios";
import vueWait from "@/plugins/vueWait";
import dayjs from "@/plugins/dayjs";
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// styles
import Colors from "@/styles/Colors.css";
import Typography from "@/styles/Typography.css";
import General from "@/styles/General.css";

Vue.config.productionTip = false;

async function init() {
  const store = createStore();

  new Vue({
    store,
    router: router(store),
    axios: axios(store),
    vuetify,
    elementUI,
    formatDate,
    General,
    Colors,
    Typography,
    dayjs,
    wait: new vueWait({ useVuex: true }),
    render: h => h(App),
  }).$mount("#app");
}

init();
