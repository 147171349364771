import axios from "axios";

import Environment from "@/environment";
axios.defaults.baseURL = Environment.API_URL;

export default function(store) {
  axios.interceptors.response.use(null, async function(error) {
    const status = error.response && error.response.status;
    if (status === 401) {
      await store.dispatch("auth/logout");
    }

    return Promise.reject(error);
  });

  axios.interceptors.request.use((config) => {
    const token = store.state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return axios;
}
