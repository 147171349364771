import _ from "lodash";
import axios from "axios";
import { Notification } from 'element-ui'

const state = {
  companies: null,
  companiesIds: null,
  token: null,
  authKind: null,
  user: null,
  name: null,
};

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
  },
  setCompaniesIds(state, companiesIds) {
    state.companiesIds = companiesIds;
  },
  setToken(state, token) {
    state.token = token;
  },
  setAuthKind(state, authKind) {
    state.authKind = authKind;
  },
  setUser(state, user) {
    state.user = user;
  },
  setName(state, name) {
    state.name = name;
  },
};

const actions = {
  async lookupAuthKind(context, { cpf }) {
    try {
      const { data } = await axios.get("/auth/kind", {
        params: {
          cpf: cpf,
        },
      });

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: "Não foi possível localizar sua conta.",
        duration: 6000,
      });
    }
  },

  async auth(context, { cpf, password, kind }) {
    try {
      const { data } = await axios.post("/auth", {
        kind,
        payload: {
          password: password,
        },
        cpf
      });

      context.commit("setToken", data.token);
      return data.token;
    } catch (err) {
      let message = "Não foi possível fazer login";
      const statusCode = _.get(err, "response.status", null);

      if (statusCode == 400) message = "Senha incorreta";
      Notification({
        type: 'error',
        message: message,
        duration: 6000,
      });
    }
  },

  async logout(context) {
    context.commit("setToken", null);
    context.commit("setAuthKind", null);
    context.commit("setUser", null);
  },

  async fetchContext(context) {
    try {
      const { data } = await axios.get("/context");

      context.commit("app/setFeatures", data && data.features, { root: true });
      context.commit("auth/setName", data && data.name, { root: true });
      context.commit("auth/setCompaniesIds", data && data.companiesIds, {
        root: true,
      });
      context.commit("auth/setCompanies", data && data.companies, {
        root: true,
      });
    } catch (err) {
      Notification({
        type: 'error',
        message: _.get(
          err,
          "response.message",
          "Não foi possível carregar as informações da sua conta"
        ),
        duration: 6000,
      });
    }
  },

  async getMe(context) {
    try {
      const { data } = await axios.get(`/${context.state.authKind}/me`);

      context.commit("setUser", data);
    } catch (err) {
      Notification({
        type: 'error',
        message: _.get(
          err,
          "response.message",
          "Não foi possível carregar as informações do seu perfil"
        ),
        duration: 6000,
      });
    }
  },

  async updateMe(context, payload) {
    try {
      const { data } = await axios.put(
        `/${context.state.authKind}/me`,
        payload
      );

      context.commit("setUser", data);
      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: _.get(
          err,
          "response.message",
          "Não foi possível atualizar as informações do seu perfil"
        ),
        duration: 6000,
      });
    }
  },

  async createLead(context, lead) {
    try {
      const { data } = await axios.post("/lead", lead);

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: _.get(
          err,
          "response.message",
          "Não foi possível enviar suas informações"
        ),
        duration: 6000,
      });
    }
  },

  async updateUser(context, { id, user }) {
    try {
      const { data } = await axios.put("/user/" + id, user);

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: "Não foi possível atualizar o usuário",
        duration: 6000,
      });
    }
  },

  async createUser(context, user) {
    try {
      const { data } = await axios.post("/user", user);

      return data;
    } catch (err) {
      console.log({
        message: "Não foi possível criar o usuário",
        duration: 6000,
      });
    }
  },

  async fetchUsers(context, filter) {
    try {
      const { data } = await axios.get("/user", { params: filter });

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: "Não foi possível carregar os usuários",
        duration: 6000,
      });
    }
  },

  async checkCPFexists(context, cpf) {
    try {
      const { data } = await axios.get("/user/" + cpf + '/check');

      return data;
    } catch (err) {
      return null
    }
  },

  async deactivateUser(context, id) {
    try {
      const { data } = await axios.delete("/user/" + id);

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: "Não foi possível desativar esse usuário",
        duration: 6000,
      });
    }
  },

  async activateUser(context, id) {
    try {
      const { data } = await axios.put("/user/" + id, {
        active: true,
      });

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        message: "Não foi possível ativar esse usuário",
        duration: 6000,
      });
    }
  },

  async recoveryAccount(context, { cpf, email }) {
    try {
      const { data } = await axios.post("/auth/recovery", {
        email,
        cpf,
      });

      return data;
    } catch (err) {
      const message = _.get(err, "response.data.error", "Problema ao conectar com o servidor");

      Notification({
        type: 'error',
        message: message,
        duration: 6000,
      });
    }
  },
};

const getters = {
  isAdmin(state) {
    const roles = state.user && state.user.roles
    return _.includes(roles, 'admin');
  },
  isLoggedIn(state) {
    return !!state.token;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
