<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6"
    >
      <v-card ref="form">
        <h2 class="primary--text pl-3 pt-3">
          {{ isEdit ? "Detalhes do evento comunicado" : "Cadastrar evento" }}
        </h2>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Registrado em:</strong>
            {{ data.createdAt | FormatDate('datetime') }}
          </div>

          <div class="my-3" v-if="data.kind">
            <strong>Tipo do evento:</strong>
            {{ mapKind(data.kind) }}
          </div>

          <v-text-field
            v-model="data.associated.name"
            label="Nome do associado"
            placeholder="Ex: John Doe"
            readonly
            required
            filled
          ></v-text-field>
          <v-text-field
            v-model="data.associated.cpf"
            label="CPF"
            placeholder="Ex: 111.111.111-11"
            v-mask="['###.###.###-##']"
            autocomplete="off"
            readonly
            filled
            required
          ></v-text-field>

          <v-textarea
            v-model="data.description"
            label="Descrição do ocorrido"
            placeholder="Ex: Lorem ipsum doe shakur."
            hide-details
            filled
            readonly
          ></v-textarea>

          <div class="mt-3 mb-5" v-if="data.occurrenceReport && data.occurrenceReport.length">
            <div><strong>Boletim de ocorrência ({{data.occurrenceReport.length}}):</strong></div>
            <v-btn  
              v-for="file in data.occurrenceReport"
              :key="file"
              color="blue"
              class="mr-2 white--text"
              @click="open(file)"
            >
              Download
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </div>

          <div class="mt-3 mb-5" v-if="data.driverLicense && data.driverLicense.length">
            <div><strong>Fotos da CNH ({{data.driverLicense.length}}):</strong></div>
            <v-btn  
              v-for="file in data.driverLicense"
              :key="file"
              color="blue"
              class="mr-2 white--text"
              @click="open(file)"
            >
              Download
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </div>


          <div class="mt-3" v-if="data.carLicense && data.carLicense.length">
            <div><strong>Fotos do CRLV ({{data.carLicense.length}}):</strong></div>
            <v-btn  
              v-for="file in data.carLicense"
              :key="file"
              color="blue"
              class="mr-2 white--text"
              @click="open(file)"
            >
              Download
              <v-icon
                right
                dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </div>

        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
<!--           <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import { mapActions } from "vuex";
import { mapWaitingActions } from "vue-wait";
import { validate } from "gerador-validador-cpf";
import checksum from '@/helpers/checksum'
import _ from "lodash";

export default {
  name: "OccurrenceEditor",

  data() {
    return {
      isEdit: false,
      initialState: null,
      data: {
        createdAt: null,
        associated: {
          name: null,
          cpf: null,
        },
        description: null,
        kind: null,
        occurrenceReport: [],
        driverLicense: [],
        carLicense: []
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != 'new' && !data) {
      this.data = null
      return this.$router.replace({ name: "occurrence" });
    }

    setTimeout(() => {
      this.initialState = Object.assign({}, this.data)
    }, 100)
  },

  watch: {
    dataIsModified(val) {
      if(!val) {
        return window.onbeforeunload = null
      }
      window.onbeforeunload = function(){
        return 'exit';
      };
    }
  },

  async beforeRouteLeave(to, from, next){
    if(!this.dataIsModified || this.confirmExit) {
      return next()
    }

    if (window.confirm("Você tem alterações que não foram salvas. Deseja realmente sair?")) {
      return next()
    }
    try {
      await this.$router.replace(from)
    } catch (err) {
      return err;
    }
  },

  computed: {
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data)
    },

    allRequiredFieldsFilled() {
      const fields = [
        'name', 
        'cpf', 
        'email'
      ]
      return fields.every((field) => this.data[field])
    },
  },

  methods: {
    ...mapActions("occurrence", [
      "create",
      "update",
    ]),
    ...mapWaitingActions("occurrence", {
      create: "creating",
      update: "updating",
    }),

    submit() {
      if (!validate(this.data.cpf)) {
        return this.$notify({
          type: 'error',
          title: 'CPF inválido',
          message: "Esse CPF é inválido, verifique se está correto",
          duration: 8000
        });
      }

      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({ type: "success", title: 'Sucesso', message: "Evento comunicado com sucesso" });
      this.reset(result)
      this.$router.replace({ name: 'occurrence' })
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.update({
        id: this.data.id,
        occurrence: data,
      });
      if (!result) return;

      this.reset(result)
      this.$notify({ type: "success", title: 'Sucesso', message: "Dados atualizados com sucesso" });
    },

    reset(result) {
      this.initialState = Object.assign({}, result)
      this.data = Object.assign({}, result)
    },

    mapKind(kind) {
      return {
        'theft': 'Roubo e/ou furto',
        'collision': 'Colisão'
      }[kind]
    },

    open(url) {
      window.open(url)
    }
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
</style>
