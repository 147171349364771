import Vue from "vue";
import dayjs from "dayjs";

// Init nonce to make relative time reactive
let nonce = {};
Vue.util.defineReactive(nonce, "fast", 0);
Vue.util.defineReactive(nonce, "slow", 0);
setInterval(() => nonce.fast++, 10000);
setInterval(() => nonce.slow++, 60000);

export default function formatDate(value, format) {
  if (!format) {
    format = "DD/MM/YY";
  } else if (format == "datetime") {
    format = "DD/MM/YY [às] HH:mm";
  } else if (format == "slim") {
    format = "DD MMM, HH:mm";
  } else if (format == "age") {
    return (
      value &&
      dayjs(value)
        .startOf("year")
        .fromNow({ withSuffix: false })
    );
  } else if (format == "fromNow") {
    let date = value && dayjs(value);
    let diff = date && dayjs().diff(date);

    if (Math.abs(diff) < 120 * 1000) {
      nonce.fast;
    } else {
      nonce.slow;
    }

    return date && date.fromNow();
  } else if (format == "duration") {
    return value && dayjs.duration(value).humanize();
  } else if (format == "longtime") {
    format = "DD MMM YY [às] HH:mm";
  } else if (format == "longerDate") {
    format = "DD MMM YYYY";
    return dayjs(String(value))
      .format(format)
      .toLowerCase();
  }

  if (value) {
    return dayjs(String(value)).format(format);
  }
}

Vue.filter("FormatDate", formatDate);
