import axios from "axios";
import _ from "lodash";
import { Notification } from 'element-ui'

const state = {};

const mutations = {};

const actions = {
  async list(context, params) {
    try {
      const { data } = await axios.get("/quotation/search", {
        params
      });

      return data
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro de carregamento',
        message: _.get(
          err,
          "response.message",
          "Não foi possível carregar as cotações"
        ),
        duration: 6000,
      });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
