<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col
      cols="12"
      sm="10"
      md="10"
      lg="10"
    >
      <v-card ref="form" class="pa-3">
        <v-row justify="center" align="center">
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <h2 class="primary--text pl-3 pt-3">
              {{ isEdit ? "Detalhes do contrato" : "Gerar contrato" }}
            </h2>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            align="right"
          >
          <v-btn
            :disabled="data && data.status == 'waiting_signature'"
            class="jutify-start mr-4 white--text"
            @click="generatePayment()"
            color="green"
            :loading="generatingLink"
          >
            <b>GERAR LINK DE COBRANÇA</b>
            <v-spacer></v-spacer>
            <img src="@/assets/dollar.svg" width="25" height="25" class="ml-2" />
          </v-btn>

            <v-btn  
              color="blue"
              class="mr-2 white--text"
              @click="openContract()"
            >
              Ver contrato
              <v-icon
                right
                dark
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Contrato criado em:</strong>
            {{ data.createdAt | FormatDate('datetime') }}
          </div>

          <div v-if="data.author">
            <div><strong>Contrato gerado por:</strong></div>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="data.author.name"
                  placeholder="Ex: John Doe"
                  solo
                  readonly
                  required
                >
                  <template v-slot:append>
                    <v-btn class="ma-0" text @click="$router.push({ name: 'user-editor', params: { id: data.author.id, data: data.author }})">
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-select
                  v-model="data.companyId"
                  :items="mapCompanies"
                  attach
                  chips
                  label="Empresa do contrato"
                  solo
                  multiple
                  readonly
                ></v-select>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="3"
            >
            <v-text-field
              v-model="contractStatus"
              label="Status do contrato"
              readonly
              hide-details
              class="mb-3"
              filled
            ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="4"
            >
            <v-text-field
              v-model="contractType"
              label="Tipo do contrato"
              readonly
              hide-details
              class="mb-3"
              filled
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
            <v-text-field
              v-model="dueDate"
              label="Venc. do contrato"
              readonly
              hide-details
              class="mb-3"
              filled
            ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="3"
            >
            <v-text-field
              v-model="recurrencePrice"
              label="Preço da mensalidade"
              readonly
              hide-details
              class="mb-3"
              filled
            ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="5"
            >
            <v-text-field
              v-model="associatedName"
              label="Nome do associado"
              placeholder="Ex: John Doe"
              readonly
              hide-details
              class="mb-3"
              filled
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="associatedDocument"
                label="CPF/CNPJ"
                placeholder="Ex: 111.111.111-44"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="associatedBirthday"
                label="Data de nascimento"
                placeholder="Ex: 23/04/1987"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <v-text-field
                v-model="associatedId"
                label="RG"
                placeholder="Ex: 33-344-334-3"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="1"
            >
              <v-text-field
                v-model="associatedIdIssuer"
                label="Órg. Emissor"
                placeholder="Ex: SSP"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="associatedEmail"
                label="Email"
                placeholder="Ex: john@company.com"
                readonly
                hide-details
                class="mb-3"
                filled
              ></v-text-field>
              <div class="disclaimer-email gray--text" v-if="data && data.status == 'waiting_signature'">
                * O associado recebeu através desse email um link para assinatura do
                contrato. Cheque a caixa de SPAM caso o email não esteja na caixa de
                entrada.
              </div>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
<!--           <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import formatCents from '@/helpers/formatCents'
import formatDate from '@/plugins/formatDate'
import { mapActions, mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";
import checksum from '@/helpers/checksum'
import _ from "lodash";

export default {
  name: "ContractEditor",

  data() {
    return {
      generatingLink: false,
      isEdit: false,
      initialState: null,
      data: {
        createdAt: null,
        author: {
          name: null,
          email: null,
          cpf: null,
          id: null,
        },
      },
    };
  },

  created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != 'new' && !data) {
      this.data = null
      return this.$router.replace({ name: "contract" });
    }

    setTimeout(() => {
      this.initialState = Object.assign({}, this.data)
    }, 100)
  },

  watch: {
    dataIsModified(val) {
      if(!val) {
        return window.onbeforeunload = null
      }
      window.onbeforeunload = function(){
        return 'exit';
      };
    }
  },

  async beforeRouteLeave(to, from, next){
    if(!this.dataIsModified || this.confirmExit) {
      return next()
    }

    if (window.confirm("Você tem alterações que não foram salvas. Deseja realmente sair?")) {
      return next()
    }
    try {
      await this.$router.replace(from)
    } catch (err) {
      return err;
    }
  },

  computed: {
    ...mapState('auth', ['companies']),
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data)
    },

    allRequiredFieldsFilled() {
      const fields = []
      return fields.every((field) => this.data[field])
    },

    mapCompanies() {
      return this.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    },

    contractStatus() {
      return {
        waiting_signature: "Aguardando assinatura",
        signed: "Contrato assinado"
      }[this.data && this.data.status];
    },

    contractType() {
      const contractType = _.get(this.data, "data.contractType", null);
      return {
        registration: "CADASTRAMENTO",
        vehicleInclusion: "INCLUSÃO DE VEÍCULO",
        vehicleReplacement: "SUBSTITUIÇÃO DE VEÍCULO",
        changeOwnership: "MUDANÇA DE TITULARIDADE"
      }[contractType];
    },

    associatedName() {
      return _.get(this.data, "data.associated.name", null);
    },

    associatedDocument() {
      return _.get(this.data, "data.associated.document", null);
    },

    associatedBirthday() {
      return _.get(this.data, "data.associated.birthday", null);
    },
    
    associatedId() {
      return _.get(this.data, "data.associated.id", null);
    },

    associatedIdIssuer() {
      return _.get(this.data, "data.associated.idIssuer", null);
    },

    associatedEmail() {
      return _.get(this.data, "data.associated.email", null);
    },

    dueDate() {
      return formatDate(_.get(this.data, "data.dueDate", new Date()));
    },

    recurrencePrice() {
      return formatCents(_.get(this.data, "data.recurrencePrice", 0));
    },
  },

  methods: {
    ...mapActions("contract", [
      "create",
      "update",
      "generatePaymentLink"
    ]),
    ...mapWaitingActions("contract", {
      create: "creating",
      update: "updating",
    }),

    submit() {
      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({ type: "success", title: 'Sucesso', message: "Contrato criada com sucesso" });
      this.reset(result)
      this.$router.replace({ name: 'contract' })
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.update({
        id: this.data.id,
        contract: data,
      });
      if (!result) return;

      this.reset(result)
      this.$notify({ type: "success", title: 'Sucesso', message: "Dados atualizados com sucesso" });
    },

    reset(result) {
      this.initialState = Object.assign({}, result)
      this.data = Object.assign({}, result)
    },

    openContract() {
      return window.open(this.data.link, "_blank");
    },

    async generatePayment() {
      this.generatingLink = true;

      const result = await this.generatePaymentLink(this.data.id);
      if (!result) return;

      window.open(result.paymentLink, "_blank");
      this.generatingLink = false;
    }
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
</style>
