import axios from "axios";
import _ from "lodash";
import { Notification } from 'element-ui'

const state = {
  showMainMenu: false,
  features: null,
};

const mutations = {
  setShowMainMenu(state, showMainMenu) {
    state.showMainMenu = showMainMenu;
  },
  setFeatures(state, features) {
    state.features = features;
  },
};

const actions = {
  async upload(context, file) {
    try {
      let formData = new FormData();
      formData.append("file", file);

      const { data } = await axios.post("/upload", formData);

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Não foi possível fazer upload',
        message: _.get(
          err,
          "response.data.error", "Erro ao comunicar com servidor"
        ),
        duration: 6000,
      });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
