<template>
  <v-card flat class="page">
    <v-card-title>
      Contratos {{ total > 0 ? `(${total})` : ''}}
      <v-spacer></v-spacer>
      <v-text-field
        :value="q"
        @input="search($event)"
        append-icon="mdi-magnify"
        solo
        label="Digite para buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="table"
      :headers="headers"
      :items="items"
      :items-per-page="limit"
      :loading="$wait.waiting('loading')"
      @click:row="goToDetail($event)"
      fixed-header
      height="inherit"
      hide-default-footer
    >
      <template slot="no-data">
        <div class="no-data-found">
          Nenhum resultado encontrado
          <v-btn @click="fetch(page)" color="blue" dark class="mt-2">
            Carregar novamente
          </v-btn>
        </div>
      </template>

      <template v-slot:item.vehicle="{ item }">
        {{ vehicleModel(item) }}
      </template>

      <template v-slot:item.dueDate="{ item }">
        {{ item.data && item.data.dueDate | FormatDate }}
      </template>

      <template v-slot:item.recurrencePrice="{ item }">
        {{ FormatCents(item.data && item.data.recurrencePrice) }}
      </template>

      <template v-slot:item.contractStatus="{ item }">
        <v-chip
          style="flex: none"
          small
          class="ml-2"
          :color="mapContractColor(item.status)"
          dark
        >
          <v-icon size="18" class="mr-1">{{
            mapContractIcon(item.status)
          }}</v-icon>
          {{ mapContractStatus(item.status) }}
        </v-chip>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | FormatDate('datetime') }}
        {{ item.author ? `(${item.author.name})` : ''}}
      </template>
    </v-data-table>
    <div class="pagination text-center py-2">
      <v-pagination
        :value="page"
        @input="fetch($event)"
        :length="pageLength"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import _ from 'lodash'
import formatCents from '@/helpers/formatCents'

export default {
  name: 'Contract',

  data() {
    return {
      q: '',
      limit: 50,
      page: 1,
      headers: [
        {
          text: 'Associado',
          align: 'start',
          filterable: false,
          value: 'data.associated.name',
        },
        { text: 'E-mail', value: 'data.associated.email' },
        { text: 'Veículo', value: 'vehicle' },
        { text: 'Vencimento', value: 'dueDate' },
        { text: 'Mensalidade', value: 'recurrencePrice' },
        { text: 'Status do contrato', value: 'contractStatus' },
        { text: 'Criado em', value: 'createdAt' },
      ],
      result: null
    }
  },

  async created() {
    this.fetch(this.page)
  },

  computed: {
    pageLength() {
      return Math.ceil(this.total/this.limit) || 1
    },

    items() {
      return _.get(this.result, 'hits', [])
    },

    total() {
      return _.get(this.result, 'total', 0)
    }
  },

  methods: {
    ...mapActions("contract", ["list"]),
    ...mapWaitingActions("contract", {
      list: 'loading'
    }),

    mapContractColor(status) {
      return {
        waiting_signature: "blue-medium",
        signed: "green"
      }[status];
    },

    mapContractIcon(status) {
      return {
        waiting_signature: "mdi-file-document-edit-outline",
        signed: "mdi-clipboard-check-outline"
      }[status];
    },

    mapContractStatus(status) {
      return {
        waiting_signature: "Aguardando assinatura",
        signed: "Contrato assinado"
      }[status];
    },

    async fetch(page) {
      this.page = page
      this.result = await this.list({
        includes: ["author"],
        page: page - 1,
        limit: this.limit
      })
    },

    goToDetail(row) {
      this.$router.push({
        name: 'contract-editor',
        params: {
          id: row.id,
          data: row
        }
      })
    },

    vehicleModel(contract) {
      return `${_.get(contract, "data.vehicle.brand", "")} ${_.get(
        contract,
        "data.vehicle.model",
        ""
      )} (${_.get(contract, "data.vehicle.year", "")})`;
    },

    search: _.debounce(async function(q){
      this.result = await this.list({ page: 0, limit: this.limit, q: q })
    }, 500, { leading: false, trailing: true }),

    FormatCents(cents) {
      return formatCents(cents)
    }
  }

}
</script>
<style type="text/css" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table {
  flex: 1 1 auto;
  height: calc(100vh - 205px);
}
.table >>> tbody tr :hover {
  cursor: pointer;
}
.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.pagination {
  flex: none;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}
</style>