<template>
  <div id="app" class="root-app ma-0">
    <div class="content-wrap white">
      <v-app>
        <MainMenu v-if="isLoggedIn" />
        <v-main>
          <transition :name="transitionName" mode="out-in">
            <router-view class="page-container"></router-view>
          </transition>
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import MainMenu from "@/components/MainMenu";

export default {
  name: "App",

  components: {
    MainMenu,
  },

  data() {
    return {
      transitionName: "slide-left",
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },

  methods: {
    ...mapMutations("app", ["setShowMainMenu"]),
  },

  watch: {
    isLoggedIn(val) {
      if (!val) {
        return this.$router.push({
          name: "login"
        });
      }
    },
    $route(to, from) {
      this.setShowMainMenu(false);
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
};
</script>

<style lang="scss" scoped>
.root-app {
  box-sizing: border-box;
}
.content-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.app-bar-layout {
  width: 100%;
}
</style>
