import axios from "axios";
import _ from "lodash";
import { Notification } from 'element-ui'

const state = {};

const mutations = {};

const actions = {
  async list(context, params) {
    try {
      const { data } = await axios.get("/inspection/search", {
        params
      });

      return data
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro de carregamento',
        message: _.get(
          err,
          "response.message",
          "Não foi possível carregar as vistorias"
        ),
        duration: 6000,
      });
    }
  },


  async approve(context, id) {
    try {
      const { data } = await axios.post("/inspection/" + id + '/approve');

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro ao atualizar',
        message: _.get(
          err,
          "response.message",
          "Não foi possível aprovar a vistoria"
        ),
        duration: 6000,
      });
    }
  },


  async decline(context, { id, notes }) {
    try {
      const { data } = await axios.post("/inspection/" + id + '/decline', { notes });

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro ao atualizar',
        message: _.get(
          err,
          "response.message",
          "Não foi possível reprovar a vistoria"
        ),
        duration: 6000,
      });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
